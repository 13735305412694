import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import "../../assets/css/Courses/index.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoursesDataThunk,
  postEnrollCoursesThunk,
} from "../../redux/thunks/CoursesThunks";
import { useLocation, useHistory } from "react-router-dom";
import "./../../assets/css/Courses/CourseFront.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthData } from "../../helper/auth";
import { SanitizeMaterialTypeId } from "../../helper/functions";
import { slugify } from "../../helper/functions";
import { removeCourseDataEnroll } from "../../redux/actions/CoursesActions";
import { courseATPI } from "../../helper/ATPI";
import MetaTag from "../../components/MetaTag/MetaTag";
import AccordionCourse from "../../components/CoursePage/AccordionCourse";
import PrerequisitesCourse from "../../components/CoursePage/PrerequisitesCourse";
import CourseDetailTitle from "../../components/CoursePage/CourseDetailTitle";
import Sertificate from "../../components/CoursePage/CourseFront/Sertificate";
import RelatedFileSection from "../../components/CoursePage/RelatedFileSection";
import Instructor from "../../components/CoursePage/CourseFront/Instructor";
import CompleteProfile from "../../components/Register/CompleteProfile";
import BreadcrumbPageNonDashboard from "../../components/BreadcrumbPageNonDashboard";
import CardBenefit from "../../components/CoursePage/CardBenefit";
import {
  loadingFinish,
  loadingStart,
} from "../../redux/reducers/CommonReducers";
import { UserService } from "../../http/UserHttp";
import moment from "moment";
import CourseSchemeCertification from "../../components/CoursePage/CourseFront/CourseSchemeCertification";
import RelatedMaterialSection from "../../components/CoursePage/RelatedMaterialSection";
const MySwal = withReactContent(Swal);

function CourseFront() {
  const { course_id, slug } = useParams();
  const [enrollAndStart, setEnrollAndStart] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [purchased, setPurchased] = useState(true);
  const [userStatus, setUserStatus] = useState();
  const [isPassed, setIsPassed] = useState(true);
  const [isPassedPrereq, setIsPassedPrereq] = useState([]);
  const [show, setShow] = useState(false);
  const [check, setCheck] = useState(false);
  const courses = useSelector((state) => state.courses);
  const course = courses?.coursesDataId;
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [membershipData, setMembershipData] = useState();
  const currURL = history?.location?.pathname.split("/");

  const renderMeta = (e) => {
    const url = "course/blogger/" + e.course_id;
    const title = e.title + " | Satukelas";
    const description = e.description;
    const image = e.thumbnail;
    // const image = imgSrc(articlePageData.response && articlePageData.response?.banner_image, 769, 330);
    const props = { url, title, description, image };
    return <MetaTag {...props} />;
  };

  useEffect(() => {
    //to track url changes, so we can re dispatch the redux data if the course_id change
    // dispatch(removeCourseDataId());
    getCoursesDataId();
  }, [location]);

  useEffect(() => {
    if (check) {
      // console.log(course?.profile_completeness, "<===");
      if (!course?.profile_completeness) {
        setShow(true);
        setCheck(false);
        return false;
      }
      // console.log(sanitizedMaterial, getIndex());
      if (course?.is_completed) {
        const sanitizedMaterial = SanitizeMaterialTypeId(
          course.topics[0].materials[0]
        );
        history.push(
          `/course/${slug}/${course_id}/${sanitizedMaterial.type}/${slugify(
            sanitizedMaterial.title
          )}/${sanitizedMaterial.id}_0_0`
        );
      } else {
        const idxs = getIndex();
        const sanitizedMaterial = SanitizeMaterialTypeId(
          course.topics[idxs.index].materials[idxs.indexL]
        );
        history.push(
          `/course/${slug}/${course_id}/${sanitizedMaterial.type}/${slugify(
            sanitizedMaterial.title
          )}/${sanitizedMaterial.id}_${idxs.index}_${idxs.indexL}`
        );
      }
    }
  }, [check]);

  useEffect(() => {
    const assId = location.state?.assId;
    if (assId) {
      if (courses.coursesDataId.topics) {
        const topics = courses.coursesDataId.topics;
        let topicIndex = 0;
        let materialIndex = 0;
        let titleCourse = slugify(courses.coursesDataId.title);
        let titleAss = "";
        for (let i = 0; i < topics.length; i++) {
          const topic = topics[i];
          for (let j = 0; j < topic.materials.length; j++) {
            const material = topic.materials[j];
            if (material.assignment_id == assId) {
              topicIndex = i;
              materialIndex = j;
              titleAss = slugify(material.title);
              break;
            }
          }
        }
        history.push(
          `/course/${titleCourse}/${course_id}/assignments/${titleAss}/${assId}_${topicIndex}_${materialIndex}`
        );
      }
    }
    // redirect to front page webinar/konsultasi
    if (courses.coursesDataId.course_type) {
      if (courses.coursesDataId.course_type.toLowerCase() === "webinar") {
        history.push(`/webinar/${course.title}/${course.course_id}`);
      }
      if (courses.coursesDataId.course_type.toLowerCase() === "konsultasi") {
        history.push(`/consultation/${course.title}/${course.course_id}`);
      }
    }

    // handle program sertifikasi atpi
    // redirects to handler atpi page
    if (courseATPI.find((e) => e.course_id === course_id)) {
      const obj = courseATPI.find((e) => e.course_id === course_id);
      history.push(`/atpi/${obj.alias}`);
    }
    if (
      history.location.pathname.includes("/techconnect/") &&
      course?.topics?.length > 0
    ) {
      let sanitizedMaterial = SanitizeMaterialTypeId(
        course.topics[0].materials[0]
      );
      if (sanitizedMaterial.type != "lessons") {
        sanitizedMaterial = SanitizeMaterialTypeId(
          course.topics[1].materials[0]
        );
      }
      history.push(
        `/techconnect/${slug}/${course_id}/${sanitizedMaterial.type}/${slugify(
          sanitizedMaterial.title
        )}/${sanitizedMaterial.id}_${
          sanitizedMaterial.type == "lessons" ? "0" : "1"
        }_0`
      );
    }
  }, [courses.coursesDataId]);

  useEffect(() => {
    if (courses.courseDataEnroll === "success" && trigger) {
      setTrigger(false);
      setEnrollAndStart(true);
      getCoursesDataId();
      dispatch(removeCourseDataEnroll());
    }
    if (courses.courseDataEnroll === "fail" && trigger) {
      setTrigger(false);
      displayPopup(false, "Gagal Memulai Kursus!", "Oke");
      dispatch(removeCourseDataEnroll());
    }
  }, [courses]);

  useEffect(() => {
    let passed = true;
    const preq = course?.prerequisites;
    if (preq?.length > 0) {
      for (let i = 0; i < preq?.length; i++) {
        if (preq[i].is_enrolled && !preq[i].is_completed) {
          passed = false;
          break;
        } else if (!preq[i].is_enrolled) {
          passed = false;
          break;
        }
      }
      setIsPassed(passed);
    }
  }, [course]);

  useEffect(() => {
    const preq = course?.prerequisites;
    if (preq?.length > 0) {
      let tmp = [];
      // looping di prasyarat course
      for (let i = 0; i < preq?.length; i++) {
        let currPreq = preq[i];
        let status;
        let enroll = true;
        // compare dengan my webinar
        if (currPreq.course_type.toLowerCase() === "webinar") {
          if (currPreq?.is_enrolled) {
            // user sudah enroll
            if (
              moment().isAfter(
                moment(currPreq?.time_ends).format("YYYY-MM-DD HH:mm:ss")
              )
            ) {
              status = "Webinar selesai";
            } else {
              // webinar belum mulai atau sedang berlangsung
              status = "Lihat webinar";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti webinar"
            status = "Ikuti webinar";
            enroll = false;
          }
        }

        if (currPreq.course_type.toLowerCase() === "pelatihan") {
          if (currPreq.is_enrolled) {
            // user sudah enroll
            if (currPreq.is_completed === "completed") {
              status = "Pelatihan selesai";
            } else {
              status = "Lanjutkan pelatihan";
            }
          } else {
            // user belum enroll
            // then status => "Ikuti pelatihan"
            status = "Ikuti pelatihan";
            enroll = false;
          }
        }

        let obj = {
          course_id: preq[i].course_id,
          course_type: preq[i].course_type,
          text: status,
          enroll,
        };
        tmp.push(obj);
      }
      setIsPassedPrereq(tmp);
    }
  }, [course]);

  function getCoursesDataId() {
    dispatch(getCoursesDataThunk({ type: "id", param: `/${course_id}` }));
  }

  let address = useLocation();

  useEffect(() => {
    if (enrollAndStart) {
      const sanitizedMaterial = SanitizeMaterialTypeId(
        course.topics[0].materials[0]
      );
      history.push(
        `/course/${slug}/${course_id}/${sanitizedMaterial.type}/${slugify(
          sanitizedMaterial.title
        )}/${sanitizedMaterial.id}_0_0`
      );
    }
  }, [course.is_enrolled]);

  function enrollPrerequisitesCourse(item) {
    const payload = {
      user_id: AuthData().userId,
      course_id: item,
    };

    if (!course?.profile_completeness) {
      setShow(true);
      return false;
    }
    setTrigger(true);
    dispatch(postEnrollCoursesThunk(payload));
  }

  function enrollCourse() {
    // check if user profile is complete
    if (course.profile_completeness === 0) {
      setShow(true);
      return false;
    } else {
      setTrigger(true);
      dispatch(
        postEnrollCoursesThunk({
          user_id: AuthData().userId,
          course_id: course_id,
        })
      );
    }
  }

  const handleLogin = () => {
    displayPopupLogin(
      false,
      "Login Terlebih Dahulu untuk Memulai Pelatihan",
      "Login"
    );
  };
  function getIndex() {
    const topics = course.topics;
    for (let i = 0; i < topics.length; i++) {
      const materials = topics[i].materials;
      for (let j = 0; j < materials.length; j++) {
        const data = { index: i, indexL: j };
        return data;
        // if (materials[j].completed === 0) {
        // } else return { index: 0, indexL: 0 };
      }
    }
  }

  const checkProfileCompleteness = () => {
    setCheck(true);
  };

  function continueCourse() {
    // check if user profile is complete
    checkProfileCompleteness();
  }

  function displayPopupLogin(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {
      if (value.isConfirmed) {
        history.push(`/login?targetUrl=${address?.pathname}`);
      }
    });
  }

  function displayPopup(cancel, title, confirm) {
    MySwal.fire({
      title: title,
      showCancelButton: cancel,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirm,
    }).then((value) => {});
  }
  useEffect(() => {
    if (AuthData().userId) getMembership();
  }, []);

  const getMembership = async () => {
    dispatch(loadingStart());
    try {
      const response = await UserService.getMembership();
      setMembershipData(response.memberships);
    } catch (error) {
      console.log(error);
    }
    dispatch(loadingFinish());
  };

  useEffect(() => {
    if (membershipData) {
      if (membershipData.length > 0) setUserStatus(membershipData[0].status);
    }
  }, [membershipData]);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <BreadcrumbPageNonDashboard
              type={course.course_type}
              slugify={slugify}
              title={course.title}
              course_id={course.course_id}
            />
          </Col>
        </Row>
      </Container>
      <CourseDetailTitle
        item={course}
        enrolled={course?.is_enrolled ? true : false}
        enrollCourse={enrollCourse}
        purchased={purchased}
        statusUser={userStatus}
        continueCourse={continueCourse}
        anotherAttempt={course.is_completed}
        isPassed={isPassed}
        description={course.description}
      />
      <Container className="course_front__container main dashboard_page__container">
        {course?.course_id && renderMeta(course)}
        <Row>
          <Col md={6}>
            {course?.prerequisites?.length > 0 ? (
              <PrerequisitesCourse
                prereq={course.prerequisites}
                type={course.course_type}
                item={course}
                enrollCourse={enrollPrerequisitesCourse}
                continueCourse={continueCourse}
                anotherAttempt={course.is_completed}
                purchased={purchased}
                isPassedPrereq={isPassedPrereq}
                statusUser={userStatus}
                enrolled={course?.is_enrolled ? true : false}
              />
            ) : null}

            {course?.course_type === "Sertifikasi" &&
              currURL[2].includes("brevet") && (
                <>
                  <CourseSchemeCertification title="Try Out" type="try out" />
                  <CourseSchemeCertification
                    title="Pembahasan Soal"
                    type="explain"
                  />
                  <CourseSchemeCertification
                    title="Tutorial Pajak"
                    type="tutorial"
                  />
                </>
              )}
            <CardBenefit objective={course.objectives} />
            <RelatedFileSection
              membershipData={membershipData}
              handouts={course.handouts}
            />
            <AccordionCourse
              detail={false}
              topics={course.topics}
              courseId={course_id}
              slug={slug}
              enrolled={course?.is_enrolled ? true : false}
              continueCourse={continueCourse}
              handleLogin={handleLogin}
              enrollCourse={enrollCourse}
            />
            {/* <CardDesc description={course.description} /> */}

            {/* {course?.skills_covered?.length > 0 ? (
              <CardCoveredSkill skillcover={course.skills_covered} />
            ) : null} */}
            {course.course_type === "Sertifikasi" ? (
              <Sertificate
                title="Bagian dari Sertifikasi"
                type="sertifikasi"
                courseName={course.title}
                detail={false}
                idTemp={course?.certificate_template_id}
                thumbTemp={course?.certificate_template_url}
              />
            ) : course.course_type === "Studi Kasus" ||
              (course?.certificate_template_url === "" &&
                course?.certificate_template_id === "") ? null : (
              <Sertificate
                title="Sertifikasi Partisipasi"
                type="pelatihan"
                detail={false}
                courseName={course.title}
                idTemp={course?.certificate_template_id}
                thumbTemp={course?.certificate_template_url}
              />
            )}
            {course?.course_type === "Sertifikasi" &&
              currURL[2].includes("brevet") && (
                <CourseSchemeCertification
                  title="Course Terkait"
                  type="related"
                  item={course?.related_material}
                />
              )}
          </Col>
          <Col md={6} className="h-inherit h-md-100 ">
            {course.topics && (
              <Instructor
                isWebinar={false}
                item={course}
                statusUser={userStatus}
                handleLogin={handleLogin}
                enrolled={course?.is_enrolled ? true : false}
                enrollCourse={enrollCourse}
                purchased={purchased}
                continueCourse={continueCourse}
                anotherAttempt={course.is_completed}
                isPassed={isPassed}
              />
            )}
          </Col>
          <Col md={12}>
            <RelatedMaterialSection
              title="Pelatihan Online Serupa"
              related={course}
              disableHover={true}
              more="Lihat Selengkapnya"
            />
          </Col>
        </Row>
        <Modal
          show={show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable={true}
          centered
        >
          <Modal.Body>
            <CompleteProfile
              par={[]}
              handleClose={() => setShow(false)}
              refreshCourseData={getCoursesDataId}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Batal
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default CourseFront;
